@import url(https://rsms.me/inter/inter.css);
@import url(https://rsms.me/inter/inter-ui.css);
html {
    font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
    html {
        font-family: 'Inter var', sans-serif;
    }
}

body,
html,
#root {
    height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

